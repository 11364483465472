// Dependencies
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Divider from '../../components/Divider';
import Loader from '../../components/Loader';
import NoDataFound from '../../components/NoDataFound';
import OpenReq from '../../components/OpenReq';
import Profile from '../../components/Profile';
import SectionHeader from '../../components/SectionHeader';
import './style.scss';

const apiUrlBase = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:4200';

function OurLab({ language }) {
  const { t, i18n } = useTranslation();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState({ Members: [], OpenReq: [] });

  useEffect(() => {
    i18n.changeLanguage(language === 'ja' ? 'ja' : 'en');
    const fetchData = async () => {
      setIsLoading(true);
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 5000);
      const result = await axios.get(`${apiUrlBase}/cms/our-lab`);
      setData(result.data);
      setIsLoading(false);
      clearTimeout(timeout);
    }
    fetchData();
  }, [i18n, language]);

  return (
    <div className="page--our-lab">
      <Loader isLoading={isLoading} />
      <div className="page--our-lab__hero-tagline">
        <div className="page--our-lab__hero-tagline__backdrop"></div>
        <h3 className="page--our-lab__hero-tagline__copy inverse">{t('OUR_LAB__HERO_TAGLINE')}</h3>
      </div>
      <div className="page--our-lab__container">
        <div className="grid">
          {/* Profiles */}
          {/* Desktop */}
          <div className="col-4 page--our-lab__profile-column__desktop">
            <div className="page--our-lab__members--first-column">
              {data.Members.filter(d => !d.fields.isAlumni).map((item, index) => {
                if (index % 3 === 0) {
                  return (
                    <Profile
                      name={language === 'ja' && item.fields.name_ja ? item.fields.name_ja : item.fields.name}
                      position={language === 'ja' && item.fields.position_ja ? item.fields.position_ja : item.fields.position}
                      description={language === 'ja' && item.fields.description_ja ? item.fields.description_ja : item.fields.description}
                      linkToCv={item.fields.cv && item.fields.cv[0].url}
                      portrait={item.fields.portrait && item.fields.portrait[0].url}
                    />
                  )
                } else {
                  return (null)
                }
              })}
            </div>
          </div>
          <div className="col-4 page--our-lab__profile-column__desktop">
            <div className="page--our-lab__members--middle-column">
              {data.Members.filter(d => !d.fields.isAlumni).map((item, index) => {
                if (index % 3 === 1) {
                  return (
                    <Profile
                      name={language === 'ja' && item.fields.name_ja ? item.fields.name_ja : item.fields.name}
                      position={language === 'ja' && item.fields.position_ja ? item.fields.position_ja : item.fields.position}
                      description={language === 'ja' && item.fields.description_ja ? item.fields.description_ja : item.fields.description}
                      linkToCv={item.fields.cv && item.fields.cv[0].url}
                      portrait={item.fields.portrait && item.fields.portrait[0].url}
                    />
                  )
                } else {
                  return (null)
                }
              })}
            </div>
          </div>
          <div className="col-4 page--our-lab__profile-column__desktop">
            <div className="page--our-lab__members--last-column">
              {data.Members.filter(d => !d.fields.isAlumni).map((item, index) => {
                if (index % 3 === 2) {
                  return (
                    <Profile
                      name={language === 'ja' && item.fields.name_ja ? item.fields.name_ja : item.fields.name}
                      position={language === 'ja' && item.fields.position_ja ? item.fields.position_ja : item.fields.position}
                      description={language === 'ja' && item.fields.description_ja ? item.fields.description_ja : item.fields.description}
                      linkToCv={item.fields.cv && item.fields.cv[0].url}
                      portrait={item.fields.portrait && item.fields.portrait[0].url}
                    />
                  )
                } else {
                  return (null)
                }
              })}
            </div>
          </div>

          {/* Tablet */}
          <div className="col-6 page--our-lab__profile-column__tablet">
            <div className="page--our-lab__members--first-column">
              {data.Members.filter(d => !d.fields.isAlumni).map((item, index) => {
                if (index % 2 === 0) {
                  return (
                    <Profile
                      name={language === 'ja' && item.fields.name_ja ? item.fields.name_ja : item.fields.name}
                      position={language === 'ja' && item.fields.position_ja ? item.fields.position_ja : item.fields.position}
                      description={language === 'ja' && item.fields.description_ja ? item.fields.description_ja : item.fields.description}
                      linkToCv={item.fields.cv && item.fields.cv[0].url}
                      portrait={item.fields.portrait && item.fields.portrait[0].url}
                    />
                  )
                } else {
                  return (null)
                }
              })}
            </div>
          </div>
          <div className="col-6 page--our-lab__profile-column__tablet">
            <div className="page--our-lab__members--middle-column">
              {data.Members.filter(d => !d.fields.isAlumni).map((item, index) => {
                if (index % 2 === 1) {
                  return (
                    <Profile
                      name={language === 'ja' && item.fields.name_ja ? item.fields.name_ja : item.fields.name}
                      position={language === 'ja' && item.fields.position_ja ? item.fields.position_ja : item.fields.position}
                      description={language === 'ja' && item.fields.description_ja ? item.fields.description_ja : item.fields.description}
                      linkToCv={item.fields.cv && item.fields.cv[0].url}
                      portrait={item.fields.portrait && item.fields.portrait[0].url}
                    />
                  )
                } else {
                  return (null)
                }
              })}
            </div>
          </div>

          {/* Mobile */}
          <div className="col-12 page--our-lab__profile-column__mobile">
            <div className="page--our-lab__members--middle-column">
              {data.Members.filter(d => !d.fields.isAlumni).map((item, index) => {
                return (
                  <Profile
                    name={language === 'ja' && item.fields.name_ja ? item.fields.name_ja : item.fields.name}
                    position={language === 'ja' && item.fields.position_ja ? item.fields.position_ja : item.fields.position}
                    description={language === 'ja' && item.fields.description_ja ? item.fields.description_ja : item.fields.description}
                    linkToCv={item.fields.cv && item.fields.cv[0].url}
                    portrait={item.fields.portrait && item.fields.portrait[0].url}
                  />
                )
              })}
            </div>
          </div>

          {/* Alumni */}
          {data.Members.filter(d => d.fields.isAlumni).length > 0 && (
            <div className="col-12">
              <h4 className="page--our-lab__alumni">{t('OUR_LAB__ALUMNI')}</h4>
            </div>
          )}
          {data.Members.filter(d => d.fields.isAlumni).map((item, index) => {
            return (
              <div className="col-3_sm-6_xs-12">
                <Profile
                  name={language === 'ja' && item.fields.name_ja ? item.fields.name_ja : item.fields.name}
                  position={language === 'ja' && item.fields.position_ja ? item.fields.position_ja : item.fields.position}
                  description={language === 'ja' && item.fields.description_ja ? item.fields.description_ja : item.fields.description}
                  linkToCv={item.fields.cv && item.fields.cv[0].url}
                  portrait={item.fields.portrait && item.fields.portrait[0].url}
                />
              </div>
            )
          })}

          {data.Members.length === 0 && 
            <div className="col-12">
              <NoDataFound />
            </div>
          }

          <div className="col-12">
            <Divider />
          </div>

          <div className="col-12">
            <SectionHeader label={t('OUR_LAB__JOIN_US__HEADER')} type={'subheader'} />
          </div>
          <div className="col-6_sm-12">
            <p className="page--our-lab__join-us__description">{t('OUR_LAB__JOIN_US__DESCRIPTION')}</p>
            {data.OpenReq.map((item, index) => (
              <div className="page--our-lab__join-us__open-req" key={`open-req--${index}`}>
                <OpenReq
                  title={language === 'ja' && item.fields.title_ja ? item.fields.title_ja : item.fields.title}
                  link={item.fields.link}
                  location={language === 'ja' && item.fields.location_ja ? item.fields.location_ja : item.fields.location}
                />
              </div>
            ))}
            {data.OpenReq.length === 0 &&
              <div className="page--our-lab__join-us__open-req">
                <p className="page--our-lab__join-us__open-req__no-open-req">{t('OUR_LAB__JOIN_US__NO_OPEN_REQ')}</p>
              </div>
            }
          </div>
          <div className="col-6_sm-12">
            <div className="page--our-lab__join-us__image-container">
              <div className="page--our-lab__join-us__image"></div>
            </div>
          </div>
          
          {/* Contact Us */}
          <div className="col-12">
            <Divider />
          </div>

          <div className="col-12">
            <SectionHeader label={t('OUR_LAB__CONTACT__HEADER')} type={'subheader'} />
          </div>
          <div className="col-6_md-12">
            <div className="page--our-lab__contact__description">
              <p>{t('OUR_LAB__CONTACT__DESCRIPTION')}</p>
            </div>
            <div className="page--our-lab__contact__phone">
              <div className="page--our-lab__contact__phone__icon"></div>
              <h6 className="page--our-lab__contact-copy">{t('OUR_LAB__CONTACT__PHONE')}</h6>
            </div>
            <div className="page--our-lab__contact__email">
              <div className="page--our-lab__contact__email__icon"></div>
              <h6 className="page--our-lab__contact-copy">{t('OUR_LAB__CONTACT__EMAIL')}</h6>
            </div>
            <div className="page--our-lab__contact__direction">
              <p>{t('OUR_LAB__CONTACT__DIRECTION')}</p>
            </div>
            <div className="page--our-lab__contact__address">
            <div className="page--our-lab__contact__address__icon"></div>
              <h6 className="page--our-lab__contact-copy">{t('OUR_LAB__CONTACT__ADDRESS')}</h6>
            </div>
            
          </div>
          <div className="col-6_md-12">
            <iframe 
              title="access-map"
              className="page--our-lab__map-iframe"
              src={
                language === 'ja'
                ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3236.796890040966!2d139.6104744158192!3d35.780365296405385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc0e85ee61939d400!2z54m55a6a5Zu956uL56CU56m26ZaL55m65rOV5Lq6IOeQhuWMluWtpueglOeptuaJgA!5e0!3m2!1sja!2sus!4v1596770709753!5m2!1sja!2sus"
                : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3236.796890040966!2d139.6104744158192!3d35.780365296405385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc0e85ee61939d400!2z54m55a6a5Zu956uL56CU56m26ZaL55m65rOV5Lq6IOeQhuWMluWtpueglOeptuaJgA!5e0!3m2!1sen!2sus!4v1596770709753!5m2!1sen!2sus"
              } 
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurLab;
